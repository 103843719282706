<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar class="darken-3" dark flat>
              <v-toolbar-title>DataLine CRM Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form
                name="loginform"
                @submit.prevent="login"
                v-model="formValid"
              >
                <v-text-field
                  @keyup.enter="login"
                  label="Email"
                  name="email"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="email"
                  :rules="emailRules"
                />

                <v-text-field
                  @keyup.enter="login"
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                  :rules="requiredRule"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="login"
                name="submit"
                type="submit"
                :disabled="!formValid"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    email: "",
    password: "",
    formValid: false,
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid"
    ]
  }),

  methods: {
    login() {
      if (!this.formValid) {
        this.$notify({
          group: "dataline",
          type: "error",
          title: "Logingehler",
          text: "Logindaten unvollständig"
        });
        return false;
      }
      this.$store
        .dispatch("user/login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          /*
          const user = response.data.data.user;
          if(!user || !user.active){
            this.$router.push("/");
            throw new Error("User not valid");
          }
*/
          return this.$store.dispatch("mandant/fetchMandantenList");
          
        }).then(() => {
          const user = this.$store.getters["user/getUser"];
          if(user.apps.rights.length < 1 && user.apps.roles.indexOf('superuser') == -1 ){
            this.$router.push("/");
            throw new Error("Kein Mandant bei User definiert.");
            
          }
          let selectedMandant = '';
        
          if(user.apps.roles.indexOf('superuser') >= 0){
            selectedMandant = 'all';
          }
          else{
            selectedMandant = user.apps.rights[0];
          }


          this.$store.dispatch("mandant/setWorkingMandant", selectedMandant).then(() => {
            this.$router.push("/offers");
          })
        })
        .catch((error) => {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Logingehler",
            text: error.response.data.error
          });
        });
    }
  }
};
</script>
